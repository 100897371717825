const eventPlaceholder = {
    id: 1,
    title: 'мы команда',
    description: 'мы молодцы',
    photo_album_url: '',
    link_to_media: '',
    photo: '',
};

export { eventPlaceholder };
