const eventPlaceholder = {
    id: 1,
    title: 'Новость 1',
    description:
        'Хорошие наааааа аааааааа ааааааа ааааааа ааааа аааааа ааааааа аааааа ааааааа ааааа аааааааа аааааааааовости',
    new_url: '',
    photo: '',
};

export {eventPlaceholder};
